import React, { Component } from 'react'
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import '../assets/scss/mdb.scss'

import AboveFold from '../sections/home_above'
import Section2 from '../sections/jump'
import Section3 from '../sections/stream'
import Section4 from '../sections/hello'
import Section5 from '../sections/details'
import Section6 from '../sections/steps'


class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>HearRo</title>
            <link rel="canonical" href="http://hearro.com/" />
          </Helmet>
          {/* <SEO title="Home" keywords={[`blockchain`, `Sovrin`, `phone`, `communication`]} /> */}
          {/* <Intro /> */}
          <AboveFold />
          <main>
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
          </main>
        </Layout>
      </>
    )
  }
}

export default App
