import React from 'react'
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from 'mdbreact'

const JumpingSection = () => {
  return (
    <MDBContainer tag="jumping" className="text-center my-5">
      <h2 className="h1-responsive text-center mb-5">Jumping Through Hoops</h2>
      <p className="lead dark-grey-text text-center w-responsive mx-auto mb-5">
        Companies struggle to connect with their customers in a highly
        personalized yet efficient way through a traditional call center
        approach.
        <br />
        And sadly, most customers have given up expecting to get good service
        over the phone or internet. Customers often find the process:
      </p>
      <MDBRow center className="no-gutters">
        <MDBCol md="3">
          <MDBIcon icon="clock" size="3x" className="cyan-text" />
          <h5 className="my-4">Time Consuming</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
        <MDBCol md="3">
          <MDBIcon far icon="angry" size="3x" className="red-text" />
          <h5 className="my-4">Frustrating</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
        <MDBCol md="3">
          <MDBIcon
            far
            icon="meh-rolling-eyes"
            size="3x"
            className="orange-text"
          />
          <h5 className="my-4">Confusing</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
      </MDBRow>
      <MDBRow center className="no-gutters">
        <MDBCol md="3">
          <MDBIcon far icon="meh-blank" size="3x" className="brown-text" />
          <h5 className="my-4">Disconnected</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
        <MDBCol md="3">
          <MDBIcon icon="cogs" size="3x" className="blue-text" />
          <h5 className="my-4">Impersonal</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
        <MDBCol md="3">
          <MDBIcon icon="user-secret" size="3x" className="black-text" />
          <h5 className="my-4">Unsecure</h5>
          {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default JumpingSection
