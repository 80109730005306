import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import { ReactComponent as Hello} from '../images/hello_bubble.svg'
import { ReactComponent as Profile} from '../images/personal_profile.svg'
import { ReactComponent as Connect} from '../images/company_connect.svg'
import { ReactComponent as Shared} from '../images/shared_interaction.svg'
import { ReactComponent as Effortless} from '../images/effortless_interaction.svg'
import { ReactComponent as Phone} from '../images/MobilePhoneVectorMockup.svg'
import ContactModal from '../components/contactmodal'

const HelloSection = () => {
  return (
    <MDBContainer>
      <section id="how1" className="my-5">
        <h2 className="h1-responsive text-center my-5">
          Say <Hello height="110px" alt="Hello"></Hello> to HearRo
        </h2>
        <p className="lead dark- w-responsive text-center mx-auto mb-5">
          Most existing phone, chat and messaging systems built for call centers
          are surprisingly out of date and behind the times. When we call customer service, we
          have to endure a seemingly endless cycle of menu options, waiting on
          hold, repeating information, transfers and starting over.
        </p>
        <MDBContainer>
          <MDBRow center>
            <MDBCol md="4">
            <Profile height="150px"></Profile>
              {/* <MDBRow className="mb-3">
                <MDBCol size="10"> */}
                  
                  <h5 className="mb-3 text-center">
                    Customers Create Secure HearRo Profile
                  </h5>
                  <p className="font-small">
                    <ul>
                      <li>Customers own their data</li>
                      <li>
                        Customers decide which data to share with which company
                      </li>
                      <li>Information always up-to-date</li>
                    </ul>
                  </p>
                {/* </MDBCol>
              </MDBRow> */}
              <Connect height="150px"></Connect>
              {/* <MDBRow className="mb-3">
                <MDBCol size="10"> */}
                  <h5 className=" mb-3 text-center">
                  Connect With Company's Customer Service Via HearRo Platform
                  </h5>
                  <p className="font-small">
                    <ul>
                      <li>Phone, Web, Chat or Email</li>
                      <li>No security questions</li>
                      <li>Identity pre-verified</li>
                      {/* <li>Trust established</li> */}
                    </ul>
                  </p>
                {/* </MDBCol>
              </MDBRow> */}
            </MDBCol>
            <MDBCol md="4">
              <Phone></Phone>
              {/* <img
                className="img-fluid"
                src="https://mdbootstrap.com/img/Mockups/Transparent/Small/iphone-portfolio1.png"
                alt=""
              /> */}
            </MDBCol>
            <MDBCol md="4">
            <Shared height="150px"></Shared>
              {/* <MDBRow className="mb-3">
                <MDBCol size="10"> */}
                  <h5 className="text-center mb-3">
                    Customer Service Interaction Shared with All Parties
                  </h5>
                  <p className="font-small">
                    <ul>
                      <li>Transparent information</li>
                      <li>History recorded via app</li>
                      <li>Pick up where you left off</li>
                      <li>Faster resolution</li>
                    </ul>
                  </p>
                {/* </MDBCol>
              </MDBRow> */}
              {/* <MDBRow className="mb-3"> */}
              <Effortless height="150px"></Effortless>
                {/* <MDBCol size="10"> */}
                  <h5 className="text-center mb-3">
                    Effortless Communication that Delivers Better Customer
                    Service
                  </h5>
                  <p className="font-small">
                    <ul>
                      <li>Faster calls</li>
                      <li>Personalized connection</li>
                      <li>Creates Raving Fans</li>
                      <li>Trust established</li>
                    </ul>
                  </p>
                {/* </MDBCol>
              </MDBRow> */}
            </MDBCol>
          </MDBRow>
          <MDBContainer tag="section" className="text-center my-5">
            <h2 className="h1-responsive  text-center my-5">
              Your customers deserve a better customer service experience.
            </h2>
            <ContactModal />
          </MDBContainer>
        </MDBContainer>
      </section>
    </MDBContainer>
  )
}

export default HelloSection
