import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBMask,
  MDBView,
  MDBBtn,
} from 'mdbreact'
import { Link } from 'gatsby'
import { ReactComponent as Breach } from '../images/data_breach.svg'
import { ReactComponent as Best } from '../images/best_customer_service.svg'
import { ReactComponent as Team } from '../images/hearro_super_team-01.svg'
import { ReactComponent as Connect } from '../images/connect_log_me.svg'

const DetailsSection = () => {
  return (
    <MDBContainer className="mt-5 text-center">
      <MDBCard className="my-5 px-5 pb-5">
        <MDBCardBody>
          <MDBRow>
            <MDBCol lg="7" xl="8">
              <h3 className=" mb-3 p-0">Who Owns Your Identity?</h3>
              <p className="dark-grey-text">
                Most of the time, when you create an account online your
                information and data is held or ‘owned’ by that organization,
                not by you. But this is not just a problem for you. It is also,
                paradoxically, a significant problem for these same companies.
                They become responsible for not just keeping it up-to-date but
                also keeping it secure from hackers. Blockchain technology
                solves this problem for both sides by allowing individuals to
                create a secure online identity that you own and control and
                that companies can trust. This is often referred to as a Self
                Sovereign Identity
              </p>
              <a
                href="https://sovrin.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MDBBtn color="primary" size="md">
                  Learn More
                </MDBBtn>
              </a>
            </MDBCol>
            <MDBCol lg="5" xl="4">
              <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
                <Breach></Breach>
                {/* <img
                className="img-fluid"
                src="https://mdbootstrap.com/img/Photos/Others/images/49.jpg"
                alt=""
              /> */}
                <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a>
              </MDBView>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="5" xl="4">
              <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
                <Best></Best>
                {/* <img
                className="img-fluid"
                src="https://mdbootstrap.com/img/Photos/Others/images/31.jpg"
                alt=""
              /> */}
                <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a>
              </MDBView>
            </MDBCol>
            <MDBCol lg="7" xl="8">
              <h3 className=" mb-3 p-0">Customer Service that Doesn't Suck</h3>
              <p className="dark-grey-text">
                Imagine no longer needing to answer 3-5 questions about your
                birth date, mother’s maiden name or your high school mascot
                before you can even speak to a customer service rep. What if you
                could securely verify your identity and communicate the service
                you needed, before connecting, so that the call or online
                interaction is faster, easier and gets resolved smoothly? Oh,
                and YOU get to keep details of the conversation for your
                records!
              </p>
              <Link to="/works">
              <MDBBtn color="primary" size="md">
                Learn More
              </MDBBtn>
              </Link>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="7" xl="8">
              <h3 className=" mb-3 p-0">The Team</h3>
              <p className="dark-grey-text">
                Frustrated with the status quo, a small team combined their
                experience setting up call centers with the crazy idea of using
                Blockchain based self sovereign identity to build a new type of
                phone system. Together they created a platform for trusted
                connections and effortless communication specifically targeted
                to take the frustration out of customer service.
              </p>
              <Link to="/team" className="ml-1 white-text">
                <MDBBtn color="primary" size="md">
                  Read More
                </MDBBtn>
              </Link>
            </MDBCol>
            <MDBCol lg="5" xl="4">
              <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
                <Team></Team>
                {/* <img
                className="img-fluid"
                src="https://mdbootstrap.com/img/Photos/Others/images/52.jpg"
                alt=""
              /> */}
                <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a>
              </MDBView>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="5" xl="4">
              <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
                <Connect></Connect>
                {/* <img
                className="img-fluid"
                src="https://mdbootstrap.com/img/Photos/Others/images/31.jpg"
                alt=""
              /> */}
                <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a>
              </MDBView>
            </MDBCol>
            <MDBCol lg="7" xl="8">
              <h3 className=" mb-3 p-0">The Future of Communication</h3>
              <p className="dark-grey-text">
                We've gotten so used to dealing with the limitations of
                untrusted communications that it can be hard to imagine how
                effortless it could be and just how important it is. We believe
                that owning your own data and having control of your
                conversations is not just a great feature but a fundamental
                right in the digital economy. Our goal at HearRo is to not only
                revolutionize customer service but to tap into the vast
                opportunity of trusted connections powered by blockchains.
              </p>
              {/* <MDBBtn color="primary" size="md">
                Learn More
              </MDBBtn> */}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  )
}

export default DetailsSection
