import React from 'react'
import {
  MDBMask,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBView,
  MDBContainer,
  MDBAnimation,
} from 'mdbreact'
import { ReactComponent as Phone } from '../images/messaging_phone_global_connect.svg'
import { ReactComponent as Indy } from '../images/hyperledger_indy.svg'
import { ReactComponent as Ursa } from '../images/hyperledger_ursa.svg'
import { ReactComponent as Sovrin } from '../images/sovrin.svg'
import { ReactComponent as Evernym } from '../images/evernym.svg'
import { ReactComponent as Twilio } from '../images/twilio-logo-blue.svg'
import ContactModal from '../components/contactmodal'
import { Link } from 'gatsby'

class HomeAbove extends React.Component {
  state = {
    collapsed: false,
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.handleTogglerClick}
      />
    )
    return (
      <div id="apppage">
        <MDBView>
          <MDBMask className="d-flex justify-content-center align-items-center gradient">
            <MDBContainer>
              <MDBRow>
                <MDBCol
                  md="6"
                  className="white-text text-center text-md-left mt-xl-5 mb-5"
                >
                  <MDBAnimation type="fadeInLeft" delay=".3s">
                    <h1 className="h1-responsive mt-sm-5">
                      Trusted Connections That Enable Effortless Communication
                    </h1>
                    <hr className="hr-light" />
                    <p className="mb-4">
                      A Blockchain Powered Phone System
                      <br />
                      Using Secure Digital Identities To <br />
                      Deliver Better Customer Service.
                    </p>
                  </MDBAnimation>
                </MDBCol>

                <MDBCol md="6" xl="5" className="mt-xl-5">
                  <MDBAnimation type="fadeInRight" delay=".3s">
                    <Phone />
                    {/* <img src={phone} alt="" className="img-fluid" /> */}
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBContainer>
                  <Link to="/contact">
                    <MDBBtn color="orange accent-4" rounded>
                      Schedule a Demo
                    </MDBBtn>
                  </Link>
                  {/* <ContactModal /> */}
                  <MDBRow className="align-items-center justify-content-center">
                    <MDBCol size="4" sm="4" md="2" className="grey-text">
                      Powered by
                    </MDBCol>
                    <MDBCol size="4" sm="4" md="2">
                      <a
                        href="https://sovrin.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Sovrin alt="Sovrin" />
                      </a>
                    </MDBCol>
                    <MDBCol size="4" sm="4" md="2">
                      <a
                        href="https://www.twilio.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Twilio alt="Twilio" />
                      </a>
                    </MDBCol>
                    <MDBCol size="4" sm="4" md="2">
                      <a
                        href="https://www.evernym.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Evernym alt="Evernym" />
                      </a>
                    </MDBCol>
                    <MDBCol size="4" sm="4" md="2">
                    <a
                        href="https://www.hyperledger.org/projects/hyperledger-indy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      <Indy alt="Hyperledger Indy" />
                      </a>
                    </MDBCol>
                    <MDBCol size="4" sm="4" md="2">
                    <a
                        href="https://www.hyperledger.org/projects/ursa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      <Ursa alt="Hyperledger Ursa" />
                      </a>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    )
  }
}

export default HomeAbove
