import React from 'react'
import {
  MDBView,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
} from 'mdbreact'
import ContactModal from '../components/contactmodal'
import Hand from '../images/streamlined_section.png'

const StreamSection = () => {
  return (
    <div id="streamsection">
    <MDBContainer className="mt-5 text-center">
      <MDBRow>
        <MDBCol>
          <MDBView>
            <MDBCardBody>
              <MDBCardTitle className="h2 white-text">
                Streamlined Communications Powered by Trust
              </MDBCardTitle>
              <MDBCardText className="grey-text w-responsive text-center mx-auto mb-5">
                HearRo redefines what a phone system can be by leveraging
                blockchain technology to create secure digital identities, which
                allow for effortless communication and a more personalized
                customer service experience.
              </MDBCardText>
              {/* <hr className="my-4" /> */}
              <MDBRow>
                <MDBCol lg="5" className="text-center text-lg-left">
                  <img
                    className="img-fluid"
                    src={Hand}
                    alt="hand scrolling phone"
                  />
                </MDBCol>
                <MDBCol lg="7">
                  <MDBRow className="mb-3">
                    <MDBCol size="1">
                      <MDBIcon icon="share" size="lg" className="grey-text" />
                    </MDBCol>
                    <MDBCol xl="10" md="11" size="10">
                      <h5 className=" text-lg-left white-text mb-3">
                        Authenticate before you call
                      </h5>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol size="1">
                      <MDBIcon icon="share" size="lg" className="grey-text" />
                    </MDBCol>
                    <MDBCol xl="10" md="11" size="10">
                      <h5 className=" text-lg-left white-text mb-3">Resolve Issues Faster</h5>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol size="1">
                      <MDBIcon icon="share" size="lg" className="grey-text" />
                    </MDBCol>
                    <MDBCol xl="10" md="11" size="10">
                      <h5 className=" text-lg-left white-text mb-3">
                        Continue where you left off - No starting over
                      </h5>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol size="1">
                      <MDBIcon icon="share" size="lg" className="grey-text" />
                    </MDBCol>
                    <MDBCol xl="10" md="11" size="10">
                      <h5 className=" text-lg-left white-text mb-3">
                        Improved Customer Satisfaction and Loyalty
                      </h5>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <div>
                <ContactModal />
              </div>
            </MDBCardBody>
          </MDBView>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div>
  )
}

export default StreamSection
